/*******************************
        Import Directives
*******************************/

/*------------------
       Theme
-------------------*/

// Disabled else cit-xxx themes does not work
//@theme: if(@@element = "default", "", @@element);

/*--------------------
   Site Variables
---------------------*/
/* Default site.variables */
@import '@{semanticThemesFolder}/default/globals/site.variables';

/* Packaged site.variables */
@import '@{themesFolder}/citynavigator/globals/site.variables';

/* Component's site.variables */
@import (optional) '@{themesFolder}/@{theme}/globals/site.variables';

/*-------------------
 Component Variables
---------------------*/
/* Default */
@import '@{semanticThemesFolder}/default/@{type}s/@{element}.variables';

@import '@{themesFolder}/citynavigator/@{type}s/@{element}.variables';

/* Packaged Theme */
@import (optional) '@{themesFolder}/@{theme}/@{type}s/@{element}.variables';

/* Site Theme */
@import (optional) '@{siteFolder}/@{type}s/@{element}.variables';

/* -------------------------
    Central Color Map
------------------------- */

/* Default */
@import '@{semanticThemesFolder}/default/globals/colors.less';

/* Packaged Theme */
@import (optional) '@{themesFolder}/@{theme}/globals/colors.less';

/* Site Theme */
@import (optional) '@{siteFolder}/globals/colors.less';


/*******************************
             Mix-ins
*******************************/

/*------------------
       Fonts
-------------------*/

.loadFonts() when (@importGoogleFonts) {
}

/*------------------
     Overrides
-------------------*/
//dirty hack to make custom styling important, otherwise custom styling is added before default styling
html {
  @import (multiple, optional) 'overrides.less';
}
