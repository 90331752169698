/*******************************
         Site Overrides
*******************************/
.ui.modal > .content {
    line-height: 1.6;

    h1 {
        color: @primaryColor
    }

    h2 {
        font-size: 14px;
        margin-bottom: 5px;
    }
}

.modal-close {
    color: @textColor;
    font-size: 36px;
    line-height: 18px;
    position: relative;
    top: 7px;
    transition: color 0.1s ease;

    &:hover {
        color: @red;
    }

    i.icon {
        text-align: right;
    }
}

// confirmation

.modal.confirmation {
    > .header {
        border: none;
    }

    .confirm {
        color: @primaryColor;
        font-size: 30px;
    }

    > .content {
        text-align: center;
        padding: 1rem;
    }

    > .actions {
        border: none;
        text-align: center;
    }
}

#ajax-modal-content {
    min-height: 75vh;

    .ui.label {
        opacity: 0.7;
    }
}

.ui.modal.small #ajax-modal-content {
    min-height: 200px;
}
