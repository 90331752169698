/*******************************
         Site Overrides
*******************************/

// Location selector
.ui.tp-contact-info-type,
.ui.tp-terminus-selector,
.ui.tp-media-source,
.ui.tp-location-select-selector {
  width: 100%;

  .grouped.fields {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-y: hidden;

    .field {
      flex: 0 0 50%;
      flex-grow: 1;

      &:first-child {
        margin: 0;
      }

      &.field-address label::before {
        content: "\f055";
      }

      &.field-location label::before {
        content: "\f015";
      }

      &.field-waypoint_walk label::before {
        font-family: "ODP Icons";
        content: "c";
      }

      &.field-waypoint_bike label::before {
        font-family: "ODP Icons";
        content: "d";
      }

      &.field-waypoint_sail label::before {
        font-family: "ODP Icons";
        content: "e";
      }

      &.field-waypoint_rider label::before {
        font-family: "ODP Icons";
        content: "x";
      }

      &.field-waypoint_mtb label::before {
        font-family: "ODP Icons";
        content: "y";
      }
    }
  }

  .ui.radio.checkbox {
    display: block;
    width: 100%;
    height: 100%;
    margin-top: 0 !important;

    label {
      position: relative;
      height: 100%;
      padding: 40px 0.5em 0.5em 0.5em;
      margin: 0;
      color: #c3c7c8;
      border: solid 1px @borderColor;
      font-size: 10px;
      text-align: center;
      @media only screen and (min-width: @tabletBreakpoint) {
        padding: 60px 1em 1em 1em;
        font-size: 14px;
        font-weight: @bold;
      }

      &::before {
        content: none;
      }

      &::before {
        content: "\f015"; // home icon
        font-family: 'icons';
        font-size: 20px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        top: 15px;
        background: none;
        border: none;
        border-radius: 0;
        width: auto;
        height: auto;
        font-weight: @normal;
        line-height: 1;

        @media only screen and (min-width: @tabletBreakpoint) {
          font-size: 40px;
        }
      }

      &::after {
        background: none;
      }
    }

    // circle / new address
    #tp_event_location_select_selector_1 ~ label {
      &::before {
        content: "\f055";
      }
    }

    // book
    #tp_deal_callToAction_type_0 ~ label,
    #tp_event_callToAction_type_0 ~ label,
    #tp_location_callToAction_type_0 ~ label {
      &::before {
        content: "\f09d";
      }
    }

    // reserve
    #tp_deal_callToAction_type_1 ~ label,
    #tp_event_callToAction_type_1 ~ label,
    #tp_location_callToAction_type_1 ~ label {
      &::before {
        content: "\f145";
      }
    }

    // verkoop
    #tp_deal_callToAction_type_2 ~ label,
    #tp_event_callToAction_type_2 ~ label,
    #tp_location_callToAction_type_2 ~ label {
      &::before {
        content: "\f291";
      }
    }

    // info
    #tp_deal_callToAction_type_3 ~ label,
    #tp_event_callToAction_type_3 ~ label,
    #tp_location_callToAction_type_3 ~ label {
      &::before {
        content: "\f129";
      }
    }

    // file icon
    [id*='_source_0'] ~ label {
      &::before {
        content: "\f382";
      }
    }

    // link icon
    [id*='_source_1'] ~ label {
      &::before {
        content: "\f0c1";
      }
    }

    input:checked ~ label {
      background: @primaryColor;
      border-color: @primaryColor;
      color: @white;

      &::before {
        background: none;
      }

      &::after {
        content: '' !important;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 12px solid @primaryColor;
        clear: both;
        background: none;
      }
    }
  }
}

.ui.tp-contact-info-type,
.ui.tp-terminus-selector {
  .grouped.fields {
    display: flex;

    .field {
      flex: 1 1 auto;

      &:first-child {
        margin: 0;
      }

      &:not(:first-child) {
        .ui.radio.checkbox {
          label {
            border-left: none;
          }
        }
      }
    }
  }
}

// checkbox toggle
.ui.toggle.checkbox {
  line-height: 16px;

  .box:before,
  label:before {
    color: @radioActiveBackground;
    content: attr(data-checkbox-off);
    padding: 2px 5px;
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
  }
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  content: attr(data-checkbox-on);
  text-align: left;
}

.ui.toggle.checkbox input ~ .box::after,
.ui.toggle.checkbox input ~ label::after,
.ui.toggle.checkbox input:checked ~ .box::after,
.ui.toggle.checkbox input:checked ~ label::after {
  margin: 0;
  border: solid 1px @borderColor;
}

.avoid-clicks {
  pointer-events: none;
}
