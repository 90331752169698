/*******************************
         Site Overrides
*******************************/

.ui.vertical.divider {
  position: static;
  z-index: 2;
  top: auto;
  left: auto;
  margin: 0rem;
  padding: 0em;
  width: auto;
  height: auto;
  line-height: 1;
  text-align: center;
  &::after,
  &::before {
    content: none;
  }
}

.field ~ div.ui.vertical.divider {
  position: absolute;
  right: -19px;
  top: 49px;
}
