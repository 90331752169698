/*******************************
         Site Overrides
*******************************/

div.file-icon {
  .ui.tiny.image {
    float: left;
    margin: 0 20px 20px 0;
    padding-top: 20px;
  }
  .content {
    padding: 10px;
    .header {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
    .extra a {
        text-decoration: underline;
    }
}

.icon.move {
  @media only screen and (max-width: @computerBreakpoint) {
    opacity: 0;
  }
}

a.ui.item.icon {
  color: currentColor;

  &:hover {
    color: @linkColor;
  }
}
