/*******************************
         Site Overrides
*******************************/
.button__close {
  color: @textColor;
  font-size: 36px;
  line-height: 18px;
  position: relative;
  top: 7px;
  transition: color 0.1s ease;
  &:hover {
    color: @red;
  }
  i.icon {
    text-align: right;
  }
}

.button__plain {
  padding: 0;
  border: 0;
  background: transparent;
  color: @linkColor;
  text-decoration: @linkUnderline;

  &:hover {
    color: @linkHoverColor;
    text-decoration: @linkHoverUnderline;
  }
}

.button__small {
  max-width: 150px !important;
}

a.linktext-underline {
  text-decoration: underline;
}

a.linktext-bold {
  font-weight: bold;
}

.tp-autocomplete .menu > .item[data-value=""] {
  display: none;
}
