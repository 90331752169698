/*******************************
         Site Overrides
*******************************/

/*--------------
     Header
---------------*/

.ui.vertical.sidebar.menu {

    @media only screen and (max-width: @computerBreakpoint) {
        left: 100%;
        transition: all 0.2s;

        &.mobilevisible {
            left: inherit;
            right: 0;
        }
    }

    > .item {
        border-top: solid 1px @verticalSideBarMenuItemBorderColor;
        padding: 20px 0;

        &::before {
            content: none;
        }

        &:first-child {
            border-top-width: 0;
        }
    }

    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.ui.visible.left.sidebar.menu ~ .pusher {
    @media only screen and (max-width: @computerBreakpoint) {
        margin-right: 0;
        margin-top: 3.5em;
        transform: none;
    }

}

.ui.vertical.menu .item > .header:not(.ui) {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: unit((20 / 14), rem);
    font-family: @primaryHeaderFont;
    font-weight: @primaryHeaderFontWeight;
}

/*--- Sub Menu ---*/

.ui.vertical.menu {
    .menu .item {
        padding-left: 40px;
        padding-right: 48px;
    }

    .item .menu .active.item {
        font-weight: @bold;
    }
}

/*--------------
   Pagination
---------------*/

.ui.pagination.menu {
    border: none;
    box-shadow: none;
    width: auto;
    transform: translateY(-1px);

    @media only screen and (max-width: @tabletBreakpoint) {
        display: block;
        text-align: center;
    }
}

.ui.pagination.menu .item:last-child {
    border-radius: 0em @borderRadius @borderRadius 0em;
}

.ui.compact.menu .item:last-child {
    border-radius: 0em @borderRadius @borderRadius 0em;
}

.ui.pagination.menu .item:last-child:before {
    display: none;
}

.ui.pagination.menu .item {
    min-width: @paginationMinWidth;
    text-align: center;
    color: @black;
    border-radius: @borderRadius;
    @media only screen and (max-width: @tabletBreakpoint) {
        display: inline-block;
    }

    &:hover {
        background: @paginationActiveBackground;
        color: @black;
    }

    &::before {
        content: none;
    }

    &:last-child {
        border-radius: @borderRadius;
    }

    &.disabled {
        opacity: 0.6;
    }
}

.ui.pagination.menu .icon.item i.icon {
    vertical-align: top;
}

/* Active */

.ui.pagination.menu .active.item {
    border-top: none;
    padding-top: @itemVerticalPadding;
    background-color: @paginationActiveBackground;
    color: @paginationActiveTextColor;
    box-shadow: none;
    font-weight: @bold;
    border-radius: @borderRadius;
}

/*--------------
   Filters
---------------*/

.filter-push {
    background: @filterBg;
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    margin: 0 0 0 -100%;
    transition: all 0.2s ease;
    padding: 1em;
    color: @filterColor;
    will-change: transform;
    -webkit-transition: none;

    @media only screen and (min-width: @tabletBreakpoint) {
        width: @filterWidth;
    }

    h2 {
        font-size: 18px;
    }

    .icon.close {
        position: absolute;
        right: 6px;
        transition: color 0.1s ease;
        cursor: pointer;

        &:hover {
            color: @red;
        }
    }

    .ui.button {
        margin-bottom: 1em;
    }
}

.modal .filter-push {
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (min-width: @tabletBreakpoint) {
        width: @filterWidth - 10px;
    }
}

#content {
    display: inline-block;
    @media only screen and (max-width: @computerBreakpoint) {
        margin-left: 1em;
        margin-right: 1em;
    }
}

label.filter-label {
    display: block;
    font-weight: 700;
    line-height: 1;
    transition: all 0.2s ease;
    z-index: 500;
    color: @filterColor;
    cursor: pointer;

    .close {
        display: none;
    }

    &:hover {
        color: @primaryColor;
    }
}

input#filters {
    display: none;
}

[data-filter=open] .filter-push {
    margin: 0;
    left: 0;
}

[data-filter=open] label.filter-label {
    .close {
        display: inline-block;
    }

    .open {
        display: none;
    }
}

[data-filter=open] #content {
    transform: translate3d(@filterWidth, 0, 0);
    margin-right: @filterWidth;
}

// with menu visible
.visible + .dimmed input#filters:checked ~ .filter-push {
    left: 0;
}

.visible + .dimmed label.filter-label {
    left: 0;
}

.visible + .dimmed input#filters:checked ~ label.filter-label {
    left: 0;
}

/*--------------
   Menu alt (second menu)
---------------*/

.column__menu--alt {
    border-right: solid 1px @borderColor;
    padding-top: 0.5em !important;
    padding-left: 0 !important;
    width: 100%;
}

.ui.vertical.menu.menu--alt {
    display: flex;
    flex-direction: row;
    text-align: right;
    background: none;
    border: none;
    white-space: nowrap;
    overflow-x: auto;
    width: 100px !important;
    min-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: @tabletBreakpoint) {
        margin-left: 1em;
        margin-right: 1em;
    }

    @media only screen and (min-width: @tabletBreakpoint) {
        white-space: normal;
        display: block;
        text-align: right;
        overflow-x: visible;
    }

    > .item {
        display: inline-block;
        padding: 0.4em 0.25em;
        color: @tabColor;
        border: none;
        border-radius: 0;
        cursor: pointer;
        margin-bottom: 1em;
        overflow: visible;
        border-bottom: 1px solid @borderColor;

        @media only screen and (min-width: @tabletBreakpoint) {
            display: flex;
            justify-content: flex-end;
            overflow: visible;
            border-bottom: none;
            margin-bottom: 0;
        }

        &:first-child.active,
        &.active:hover,
        &.active,
        &:hover {
            background: none;
            position: relative;
            font-weight: @bold;

            &::before {
                display: block !important;
                content: "";
                width: 23px;
                height: 8px;
                position: absolute;
                right: 50%;
                top: 83%;
                left: auto;
                border-top: 2px solid @white;
                background: @white;

                @media only screen and (min-width: @tabletBreakpoint) {
                    top: 19px;
                    right: -23px;
                    transform: rotate(90deg);
                }
            }

            &::after {
                content: "";
                position: absolute;
                margin: auto;
                top: 83%;
                right: 50%;
                width: 16px;
                height: 16px;
                transform: rotate(45deg);
                border-right: 1px solid @borderColor;
                border-bottom: 1px solid @borderColor;
                background: @white;

                @media only screen and (min-width: @tabletBreakpoint) {
                    top: 15px;
                    right: -22px;
                    transform: rotate(135deg);
                }
            }
        }
    }

    .item-txt {
        padding: 0.7em 0.75em 0.6em 0.75em;
        border-radius: @borderRadius;

        + .ui.label.circular {
            margin: 0.9em 0.75em 0em -0.7em !important;
        }
    }

    .ai-highlight .item-txt {
        color: @white;
        background: @primaryColor;
        font-weight: @bold;
    }

    .item__wrapper {
        position: relative;
    }
}

.ui.vertical.menu .item:before {
    display: none;
}

.ui.tabular.menu {
    &::-webkit-scrollbar {
        height: 8px;
    }

    .item {
        border-color: @tabBg;
        margin-right: -1px;

        &.active {
            margin-bottom: 0;
            font-weight: normal;
        }
    }
}

/*--------------
   Menu top
---------------*/

.menu__top {
    .menu__wrapper {
        display: inline-block;

        a {
            color: @grey;
            text-decoration: underline;
            margin-right: 2px;
            padding-right: 6px;
            border-right: solid 1px;
            font-size: 0.8rem;

            @media only screen and (min-width: @largeMonitorBreakpoint) {
                font-size: 1rem;
            }

            &:last-of-type {
                margin-right: 0;
                border-right: none;
            }

            &:hover {
                color: @primaryColor;
            }
        }
    }

    .ui.close.icon {
        width: 1em;
        margin-right: 0;
        text-align: center;
    }

    .button__close {
        top:auto;
        margin-left: 0.4rem;
    }
}

.ui.grid > .column.menu__top {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    padding-top: 0.5rem;
}

.menu__top-sticky .column:first-child:not(.menu__top) {
    padding-top: 1.1rem;
    margin-top: -0.1rem;
}

body:not(.tox-fullscreen) .menu__top-sticky {
    position: sticky;
    top: 48px;
    z-index: 1001;
    background-color: @white;
    margin-top: -1.3rem;

    @media only screen and (min-width: @computerBreakpoint) {
        top: 0;
    }
}

#content > .menu__top-sticky.ui.grid:first-child {
    margin-bottom: 1em;
}

/*--------------
    Menu bottom
---------------*/
body:not(.tox-fullscreen) {
    .menu__bottom-sticky {
        margin-top: 1em;
        bottom: 15px;
        background: @white;

        .menu button__small {
            margin: 0 auto !important;
        }

        > .five.wide.column {
            display: flex;
        }

        @media only screen and (max-width: @tabletBreakpoint) {
            .column.right {
                order: -1;
            }

            .pagination.ui.sidebar {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.tp-bulk-buttons {
    > form,
    .button {
        height: 100%;
    }

    + .button__small {
        margin-top: 0;
    }
}

#ajax-modal-content .menu__bottom-sticky {
    position: relative;
}

/*--------------
   City navigator
---------------*/

.citynavigator-block-menu {
    margin-top: 1em;
    margin-bottom: 2em;

    &.grouped.fields {
        width: 100%;
        display: flex;

        .field {
            flex: 1;

            &:nth-child(even) {
                a {
                    border-left: 0;
                    border-right: 0;
                }
            }
        }
    }

    a {
        padding: 1em 1em 1em 1em;
        border: solid 1px @borderColor;
        color: #C3C7C8;
        font-size: 18px;
        font-weight: @bold;
        text-align: center;
        position: relative;
        display: block;

        &.active {
            background: @primaryColor;
            border-color: @primaryColor;
            color: @white;

            &::after {
                content: '' !important;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 100%;
                width: 0;
                height: 0;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
                border-top: 12px solid @primaryColor;
                clear: both;
            }
        }

        span {
            display: block;

            &.large {
                font-size: 2.5rem;
                font-weight: @bold;
                margin-bottom: 0.5em;
            }
        }
    }
}

/*--------------
   Mobilemenu
---------------*/

.ui.top.sidebar.mobilemenu {
    height: 3.5em !important;
    background-color: @topSideBarMobileMenuBackgroundColor;
    overflow: hidden !important;

    span.image-logo {
        height: 1.5em;
        margin-left: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .sidebar.icon {
        margin-top: 1em;
        margin-right: 1em;
        cursor: pointer;
    }

    @media only screen and (min-width: @computerBreakpoint) {
        display: none;
    }
}

#mobilemenu {
    margin-top: 0;
    padding: 1em;
    padding-left: 1em !important;
    height: 100%;
}

/*--------------
   servicelink
---------------*/

.servicelink {
    margin: 0;
    padding: 1em 0 0 0;
    list-style: none;

    li {
        margin: 0;
    }

    a {
        color: @grey;
        text-decoration: underline;
    }
}

.sidebar .servicelink {
    margin: 0;
    padding: 1em 0;
    list-style: none;
    border-top: 1px solid @sidebarServiceLinkBorderColor;

    li {
        margin: 0 0 0.5em 0;
    }

    a {
        color: @sidebarServiceLinkColorLink;
        opacity: 0.75;
        text-decoration: underline;
    }
}
