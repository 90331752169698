/*******************************
        Site Overrides
*******************************/

.ui.icon.message {
    position: relative;
    z-index: 10;
}

.ui.form .field.error .checkbox:not(.toggle):not(.slider) .box:before,
.ui.form .field.error .checkbox:not(.toggle):not(.slider) label:before,
.ui.form .fields.error .field .checkbox:not(.toggle):not(.slider) .box:before,
.ui.form .fields.error .field .checkbox:not(.toggle):not(.slider) label:before {
    background-color: transparent;
}
.ui.form .field.error .ui.labeled.dropdown{
    margin-top: 0;
    background-color: @orange;
    color: @white;
    border: 0;
    .text{
        background: transparent;
        color: @white;
    }
}
.unauthorised .ui.icon.message.negative {
    text-align:left;
}
