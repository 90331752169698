/*******************************
         Site Overrides
*******************************/

.ui.form .field .dropdown .menu > .item {
    font-size: @fontSize * 0.75;
}

.tp-location-select-location {
    margin-bottom: 2em;
}

.ui.form .timepicker {
    margin-left: -1em;

    .field {
        display: flex;
        align-items: center;
    }

    label {
        display: inline-block;
        margin-right: 10px;
    }

    input.tp-timepicker {
        display: inline-block;
    }

    ~ .button {
        margin-left: -1em;
    }
}

.inline.field {
    .tp-timepicker {
        display: inline-block;
    }
}

.ui.form .field {
    &.startpoint,
    &.endpoint {
        margin-top: 1em;

        .awesome-marker {
            position: relative;
            display: inline-block;
            outline: 0;
        }

        .awesome-marker-icon-white {
            margin: 11px 0 0 2px;
        }
    }

    .waypoint {
        display: inline-flex;

        &::before {
            position: absolute;
            content: '';
            left: -2px;
            top: -2px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }
    }


    &.startpoint .waypoint {
        &::before {
            background: #007C00;
        }

        &::after {
            font-family: "ODP Icons";
            position: absolute;
            content: "b";
            left: 1px;
            top: -2px;
            font-size: 7px;
            line-height: 13px;
            color: white;
        }
    }

    &.endpoint .waypoint {
        &::before {
            background: #E8084E;
        }

        &::after {
            font-family: "ODP Icons";
            position: absolute;
            content: "r";
            left: 1px;
            top: -3px;
            font-size: 6px;
            line-height: 15px;
            color: white;
        }
    }
}

// custom
.ui.form .grouped.fields {
    display: flex;

    .field {
        flex: 0 0 50%;
        margin: 0;
        display: flex;
        align-items: center;

        &:first-child {
            margin-right: 10px;
        }

        label {
            margin-right: 5px;
        }
    }
}

.ui.form input[type=text]::placeholder, .ui.form input[type=password]::placeholder {
    color: @grey;
}

// required fields
.ui.form {
    .required.field {
        .dividing.header {
            margin-top: 2rem;
        }

        &:first-child {
            .dividing.header {
                margin-top: 0;
            }
        }

        > .ui.button {
            display: inline-block;
            margin: 1em 1em 1em 0;
        }
    }

    .field {
        &.field-action > label {
            display: inline-block;
            width: 20px;
            height: 10px;
        }

        &.hidden {
            display: none;
        }
    }
}

// TOM select

:root {
    --ts-pr-clear-button: 0;
    --ts-pr-caret: 0;
    --ts-pr-min: .75rem;
}

.ts-control {
    font-family: @inputFont;
    margin: 0;
    outline: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: @inputLineHeight;
    padding: @inputPadding;
    font-size: @inputFontSize;
    background: @inputBackground;
    border: @inputBorder;
    color: @inputColor;
    border-radius: @inputBorderRadius;
    box-shadow: @inputBoxShadow;
    transition: @inputTransition;

    > input[type="text"] {
        width: initial !important;
    }
}

.plugin-clear_button {
    --ts-pr-clear-button: 1em;
}

.ts-wrapper {
    &.form-select,
    &.single {
        --ts-pr-caret: 2.25rem;
    }

    &:not(.form-control, .form-select).single .ts-control {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }

    &.multi .ts-control > div {
        background: @midWhite;
        border: 1px solid @lightBlack;
        color: @textColor;
        border-radius: @absoluteBorderRadius;

        &.active {
            background: @lightBlack;
        }
    }

    &.plugin-remove_button:not(.rtl) .item .remove {
        border-left: 0;
    }

    &.plugin-remove_button .item .remove:hover {
        background: @lightBlack;
    }
}

.ts-dropdown {
    margin: 0;
    font-size: 11px;

    .optgroup-header {
        text-transform: uppercase;
        font-weight: 700;
        color: @black;
    }

    .create,
    .no-results,
    .optgroup-header,
    .option {
        padding: 0.75em 1.25em;
    }
}

.tp-inline-icon {
    height: 1em;
    width:  1em;
}
