/*******************************
SEO Meta preview
*******************************/

.seo-meta__title {
    margin: 0;
    padding: 0;
    color: #1A0DAB;
    font-family: arial, sans-serif;
    font-size: 20px;
    line-height: 1.3;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.seo-meta__url {
    padding-top: 1px;
    color: #006621;
    font-family: arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
}

.seo-meta__content {
    color: #545454;
    font-family: arial, sans-serif;
    font-weight: normal;
    line-height: 1.57;
    word-wrap: break-word;
}

/*******************************
SEO Twitter preview
*******************************/

.ui.padded.segment.seo-tw__wrapper {
    position: relative;
    padding-left: 5em;
    font: normal normal 16px/1.4 Helvetica, Roboto, 'Segoe UI', Calibri, sans-serif;
}

.seo-tw__icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1.5em;
    left: 1em;
    width: 3em;
    height: 3em;
    background: #000;
    border-radius: 50%;

    .icon {
        margin: 2px 0 0 2px;
        font-size: 1.6em;
        line-height: 1;
        fill: #fff
    }
}

.seo-tw__meta {
    color: #697882;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.seo-tw__content {
    display: block;
    margin-top: 0.2em;
}

.seo-tw__tweet-title {
    display: block;
    margin-bottom: 0.5em;
    font-size: 1.2em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ui.segment.seo-tw__inner-wrapper {
    margin-bottom: 0;
    border-radius: 1.5em;
    overflow: hidden;
}

.seo-tw__tweet-content {
    margin-bottom: 0.2em;
}

.seo-tw__tweet-url {
    color: #697882;
}

.seo-tw__tweet-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 18em;
    background-size: cover;
    background-position: center center;

    &:not([hidden='']) {
        + .seo-tw__tweet-fallback-img {
            display: none;
        }

        ~ .active.tab {
            padding-top: 18em;
        }
    }
}

.seo-tw__tweet-fallback-img {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 6em;
    background: #F3F7F9;
    border-right: 1px solid @lightGrey
}

.seo-tw__tweet-img[hidden=''] ~ .tab {
    padding-left: 6em;
}

/*******************************
SEO Open graph preview
*******************************/

.ui.segment.seo-og__wrapper {
    border-radius: 4px;
    overflow: hidden;
}

.seo-og__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24em;
    background-size: cover;
    background-position: center center;

    &:not([hidden='']) {
        + .seo-og__fallback-img {
            display: none;
        }

        ~ .active.tab {
            padding-top: 24em;
        }
    }
}

.seo-og__img[hidden=''] ~ .tab {
    padding-left: 6em;
}

.seo-og__url {
    display: block;
    margin-bottom: 0.2em;
    color: #697882;
    text-transform: uppercase;
}

.seo-og__title {
    display: block;
    margin-bottom: 0.5em;
    font-size: 1.2em;
    line-height: 1.4em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.seo-og__fallback-img{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    height: 100%;
    width: 6em;
    background: #F3F7F9;
    border-right: 1px solid @lightGrey
}
