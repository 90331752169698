/*******************************
         Site Overrides
*******************************/
@filterWidth: 270px;

.ui.left.sidebar,
.ui.right.sidebar {
    padding: 0 25px !important;
    border: none;

    &.visible {
        box-shadow: none;
    }
}

.ui.left.sidebar {
    width: 100%;
    @media only screen and (min-width: @tabletBreakpoint) {
        width: @filterWidth;
    }
}

.pushable > .pusher {
    transition: transform .2s ease, margin 0.2s ease;
}

.ui.visible.left.sidebar ~ .pusher {
    margin-right: @width;
}

.toggle__sidebar {
    flex: 0 0 @menuToggleWidth;
    background: @black;
    color: white;
    text-align: center;
    cursor: pointer;
    position: relative;
    z-index: 999;
    transition: width 0.25s ease;
    min-height: 100vh;

    div {
        height: @menuToggleWidth;
        width: @menuToggleWidth;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: @primaryColor;
        transition: width 0.2s ease;

        span {
            display: block;
        }
    }
}

.ui.left.vertical.visible.sidebar.menu {
    @media only screen and (max-width: @computerBreakpoint) {
        margin-top: 3.5em !important;
        height: ~'calc(100% - 3.5em)' !important;
    }
}

.ui.vertical.sidebar.menu {
    @media only screen and (max-width: @tabletBreakpoint) {
        width: 100%;
    }
    @media only screen and (min-width: @computerBreakpoint) {
        z-index: 0;
    }
}

/*--------------
   Filtersidebar
---------------*/

.ui.sidebar {
    overflow: inherit !important;
}

.modal .ui.sidebar {
    overflow-y: auto !important;
}

.ui.sidebar.filtersidebar {
    position: absolute;
    box-shadow: none;
    z-index: 1060;
    @media only screen and (max-width: @computerBreakpoint) {
        margin-top: 0 !important;
    }

    &[data-filter=open] {
        @media only screen and (max-width: @tabletBreakpoint) {
            width: 98vw;
        }
    }

    &[data-filter=open].filter-push {
        left: 270px;
    }

    .filter-push {
        padding-bottom: 4em;
        pointer-events: visible;

        label.filter-label {
            position: absolute;
            right: 0;
        }

        h2 {
            margin-top: 0.5em;
        }
    }

    &.left {
        left: -150%;

        &[data-filter=open] {
            left: -13px;
            @media only screen and (min-width: @computerBreakpoint) {
                left: -270px;
            }
        }
    }

    &.right {
        right: -150%;

        &[data-filter=open] {
            right: -13px;
            @media only screen and (min-width: @computerBreakpoint) {
                right: -270px;
            }
        }
    }
}

// When filters are within the main content, they should fall over the main menu
#content [data-filter=open] {
    z-index: 1006; // 1006 for mobile sticky header
}

/* Modal */
#ajax-modal-content {
    .content-container {
        height: auto;
        max-height: 75vh;
        margin-bottom: 40px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    @media only screen and (min-width: @tabletBreakpoint) {
        [data-filter=open] {
            &.left {
                left: 0;
            }

            &.right {
                right: 0;
            }
        }
    }
}

.tox.tox-tinymce.tox-fullscreen {
    @media only screen and (min-width: @computerBreakpoint) {
        max-width: calc(100vw - @filterWidth);
    }
}
