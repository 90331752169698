/*******************************
        User Overrides
*******************************/
.announcement__wrapper {
    margin-top: 1em;
    margin-right: 0.5em;
    display: inline-block;
    position: relative;

    i {
        cursor: pointer;

        &:hover {
            ~ .ui.special.popup {
                display: block;
                top: auto;
                bottom: 125%;
                right: auto;
                background: @black;
                color: @white;
                min-width: 200px;

                &::before {
                    bottom: -4px;
                    background: @black;
                }

                > div {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.ui.bottom.popup::before {
    border-color: @white transparent transparent @white;
    border-width: 5px;
    border-style: solid;
    background-color: transparent;
}

.ui.top.left.popup:before {
    border-color: transparent @white @white transparent;
    border-width: 5px;
    border-style: solid;
    background-color: transparent;
}

.ui.popup.calendar {
    min-width: 345px;
    max-width: 400px;

    table {
        position: relative;
    }

    .link {
        cursor: pointer;
    }

    .next.link,
    .prev.link {
        position: absolute;
        top: 0.5em;
    }

    .next.link {
        right: 0.5em;
    }

    .prev.link {
        left: 0.5em;
    }
}

.question__minipopup.button {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    border-radius: 3rem;
    padding: 0.75em;
    color: @white;
    background: @lightBrown;

    @media only screen and (min-width: @computerBreakpoint) {
        padding: 1.25em;
    }

    &:hover,
    &:focus {
        color: @white;
        background: @orange;
    }
}

.question__icon {
    padding: 0 0.44em;
    color: @lightBrown;
    background: @white;
    border-radius: 50%;

    @media only screen and (min-width: @computerBreakpoint) {
        margin-right: 0.4em;
    }
}

.question__popup {
    a {
        text-decoration: underline;
    }

    .ui.button {
        margin-bottom: 0.5em;
    }
}

[data-tooltip][data-variation~="visible"],
[data-tooltip]:hover {
    z-index: 1;
}