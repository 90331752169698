/*******************************
         Site Overrides
*******************************/
*,
*:before,
*:after {
    box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
    font-family: @pageFont;
}

button > i {
    pointer-events: none;
}

p a {
    text-decoration: underline;
}

/* Mobile */

@media only screen and (max-width: 767px) {
    [class*="mobile hidden"],
    [class*="tablet only"]:not(.mobile),
    [class*="computer only"]:not(.mobile),
    [class*="large monitor only"]:not(.mobile),
    [class*="widescreen monitor only"]:not(.mobile),
    [class*="or lower hidden"] {
        display: none !important;
    }
}

/* Tablet / iPad Portrait */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    [class*="mobile only"]:not(.tablet),
    [class*="tablet hidden"],
    [class*="computer only"]:not(.tablet),
    [class*="large monitor only"]:not(.tablet),
    [class*="widescreen monitor only"]:not(.tablet),
    [class*="or lower hidden"]:not(.mobile) {
        display: none !important;
    }
}

/* Computer / Desktop / iPad Landscape */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    [class*="mobile only"]:not(.computer),
    [class*="tablet only"]:not(.computer),
    [class*="computer hidden"],
    [class*="large monitor only"]:not(.computer),
    [class*="widescreen monitor only"]:not(.computer),
    [class*="or lower hidden"]:not(.tablet):not(.mobile) {
        display: none !important;
    }
}

/* Large Monitor */

@media only screen and (min-width: 1200px) and (max-width: 1919px) {
    [class*="mobile only"]:not([class*="large monitor"]),
    [class*="tablet only"]:not([class*="large monitor"]),
    [class*="computer only"]:not([class*="large monitor"]),
    [class*="large monitor hidden"],
    [class*="widescreen monitor only"]:not([class*="large monitor"]),
    [class*="or lower hidden"]:not(.computer):not(.tablet):not(.mobile) {
        display: none !important;
    }
}

/* Widescreen Monitor */

@media only screen and (min-width: 1920px) {
    [class*="mobile only"]:not([class*="widescreen monitor"]),
    [class*="tablet only"]:not([class*="widescreen monitor"]),
    [class*="computer only"]:not([class*="widescreen monitor"]),
    [class*="large monitor only"]:not([class*="widescreen monitor"]),
    [class*="widescreen monitor hidden"],
    [class*="widescreen monitor or lower hidden"] {
        display: none !important;
    }
}

@font-face {
    font-family: "ODP Icons";
    src: url("@{themeFontPath}/icons-custom.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('../../../themes/citynavigator/assets/fonts/rubik-v23-latin-regular.woff2') format("woff2");
}

@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('../../../themes/citynavigator/assets/fonts/rubik-v23-latin-500.woff2') format("woff2");
}

@font-face {
    font-family: 'NoeDisplay';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('../../../themes/citynavigator/assets/fonts/NoeDisplay-Regular.woff2') format("woff2");
    ascent-override: 100%;
}

@font-face {
    font-family: 'NoeDisplay';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('../../../themes/citynavigator/assets/fonts/NoeDisplay-Semibold.woff2') format("woff2");
    ascent-override: 100%;
}

@font-face {
    font-family: 'NoeDisplay';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('../../../themes/citynavigator/assets/fonts/NoeDisplay-Bold.woff2') format("woff2");
    ascent-override: 100%;
}

[data-icon]:before {
    font-family: "ODP Icons" !important;
    content: attr(data-icon);
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    text-decoration: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-odp-"]:before,
[class*=" icon-odp-"]:before {
    font-family: "ODP Icons" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    text-decoration: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/** css marker icon **/
.marker-icon-odp {
    position: relative;
    text-align: center;
    vertical-align: middle;
}

.marker-icon-odp i:nth-child(1) {
    font-size: 100%;
}

.marker-icon-odp i:nth-child(2) {
    font-size: 45% !important;
    position: absolute;
    top: 0;
    left: 0;
    margin: 10% auto 0 auto;
    width: 100%;
    height: 100%;
    line-height: 85%;
}

/** item: types **/
.icon-odp-item:before {
    /** default item icon, alias .icon-odp-facilities **/
    content: "u";
}

.icon-odp-route:before {
    content: "i";
}

.icon-odp-location:before {
    /** alias .icon-odp-conference-meeting-facilities **/
    content: "l";
}

.icon-odp-event:before {
    /** also matches item category (!) **/
    content: "p";
}

/** item: route **/
.icon-odp-start:before {
    content: "b";
}

.icon-odp-end:before {
    content: "r";
}

.icon-odp-poi:before {
    /** default poi icon, alias .icon-odp-sights **/
    content: "q";
}

.icon-odp-rider:before {
    content: "x";
}

.icon-odp-mtb:before {
    content: "y";
}

/** item: categories **/
.icon-odp-walking:before {
    content: "c";
}

.icon-odp-cycling:before {
    content: "d";
}

.icon-odp-sailing:before {
    content: "e";
}

.icon-odp-recreation-area:before {
    content: "j";
}

.icon-odp-top:before {
    content: "k";
}

.icon-odp-conference-meeting-facilities:before {
    content: "l";
}

.icon-odp-food-drink:before {
    content: "m";
}

.icon-odp-overnight:before {
    content: "n";
}

.icon-odp-facilities:before {
    content: "u";
}

.icon-odp-sights:before {
    content: "q";
}

/** misc **/
.icon-odp-tree:before {
    content: "g";
}

.icon-odp-marker:before {
    content: "f";
}

.icon-odp-mapmarker:before {
    content: "h";
}

.tiktok.icon:before {
    font-family: "ODP Icons";
    content: "z";
}

.awesome-marker {
    background: url('@{themeImagePath}/markers-soft.png') no-repeat 0 0;
    width: 35px;
    height: 46px;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    text-align: center;

    &::before {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50% 50% 50% 0;
        position: absolute;
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -16px 0 0 -16px;
    }
}

.awesome-marker i {
    color: #333;
    margin-top: 10px;
    display: inline-block;
    font-size: 14px;
}

i.awesome-marker-icon-white {
    color: #FFFFFF;
}

i.awesome-marker-icon-red {
    color: #CE290F;
}

i.awesome-marker-icon-orange {
    color: #FF7700;
}

i.awesome-marker-icon-gold {
    color: #DDAC22;
}

i.awesome-marker-icon-olive {
    color: #A5B77D;
}

i.awesome-marker-icon-greenyellow {
    color: #9FC810;
}

i.awesome-marker-icon-forestgreen {
    color: #30B839;
}

i.awesome-marker-icon-darkgreen {
    color: #378C4D;
}

i.awesome-marker-icon-aquamarine {
    color: #05D3C3;
}

i.awesome-marker-icon-lightblue {
    color: #9BDDDD;
}

i.awesome-marker-icon-freshblue {
    color: #39AADD;
}

i.awesome-marker-icon-steelblue {
    color: #5584AA;
}

i.awesome-marker-icon-royalblue {
    color: #0068A4;
}

i.awesome-marker-icon-indigo {
    color: #3121A5;
}

i.awesome-marker-icon-royalpurple {
    color: #A81BC1;
}

i.awesome-marker-icon-fuchsia {
    color: #FF00E0;
}

i.awesome-marker-icon-plum {
    color: #B75A9E;
}

i.awesome-marker-icon-salmon {
    color: #F18C80;
}

i.awesome-marker-icon-skintone {
    color: #F4D1AE;
}

i.awesome-marker-icon-whitesmoke {
    color: #000000;
}

i.awesome-marker-icon-black {
    color: #000000;
}


/* Colors */
div.awesome-marker-icon-red {
    background-position: 0 0;
}

div.awesome-marker-icon-orange {
    background-position: -36px 0;
}

div.awesome-marker-icon-gold {
    background-position: -72px 0;
}

div.awesome-marker-icon-olive {
    background-position: -108px 0;
}

div.awesome-marker-icon-greenyellow {
    background-position: -144px 0;
}

div.awesome-marker-icon-forestgreen {
    background-position: -180px 0;
}

div.awesome-marker-icon-darkgreen {
    background-position: -216px 0;
}

div.awesome-marker-icon-aquamarine {
    background-position: -252px 0;
}

div.awesome-marker-icon-lightblue {
    background-position: -288px 0;
}

div.awesome-marker-icon-freshblue {
    background-position: -324px 0;
}

div.awesome-marker-icon-steelblue {
    background-position: -360px 0;
}

div.awesome-marker-icon-royalblue {
    background-position: -396px 0;
}

div.awesome-marker-icon-indigo {
    background-position: -432px 0;
}

div.awesome-marker-icon-royalpurple {
    background-position: -468px 0;
}

div.awesome-marker-icon-fuchsia {
    background-position: -504px 0;
}

div.awesome-marker-icon-plum {
    background-position: -540px 0;
}

div.awesome-marker-icon-salmon {
    background-position: -576px 0;
}

div.awesome-marker-icon-skintone {
    background-position: -612px 0;
}

div.awesome-marker-icon-whitesmoke {
    background-position: -648px 0;
}

div.awesome-marker-icon-black {
    background-position: -684px 0;
}

.avoid-clicks {
  pointer-events: none;
}
