@import "../components/seo.less";

/*******************************
         Site Overrides
*******************************/

#wrapper {
    display: flex;

    &.full.height {
        min-height: 100vh;
        @media only screen and (min-width: @tabletBreakpoint) {
            min-height: calc(~'100vh' -50px);
        }
        @media only screen and (min-width: @computerBreakpoint) {
            min-height: 100vh;
        }
        max-height: 100%;
    }
}

#content {
    padding: 1em 0em 1em 0em;
    flex: 1;
    will-change: transform;
    max-width: 100%;
    @media only screen and (min-width: @computerBreakpoint) {
        padding: 2em 1em 1em 2em;
    }
}

// height 100%
#content,
#filter-push,
#wrapper,
.pusher {
    min-height: 100%;
}

h1 {
    color: @primaryColor;
}

.logo__icon {
    width: 30px;
    margin: 0 auto 5px auto;
}

.image-logo {
    background: left bottom / contain no-repeat url("@{themeImagePath}/logo.svg");
    display: block;
    height: 50px;
    width: 235px;
}

.sidebar .image-logo {
    filter: brightness(0) invert(1);
    height: 35px;
    max-width: 210px;
}

// user login
.unauthorised {
    margin-block: 0 !important;

    .logo {
        span.image-logo {
            height: 40px;
        }

        span.slogan {
            float: left;
            font-size: 12px;
            letter-spacing: 2px;
            color: @black;
            margin-top: 5px;
        }
    }

    .ui.segment {
        padding: 2em 2em 2.5em 2em;

        &.logo {
            background-color: @primaryColor;
        }
    }

    .ui.segment:first-child {
        border-radius: 5px 5px 0 0;
    }

    .ui.segment:nth-child(2) {
        border-radius: 0 0 5px 5px;
        background-color: rgba(255, 255, 255, 0.7);
    }

    .forget {
        text-decoration: underline;
    }
}

.unauthorised__background-wrapper {
    padding: 0 !important;
    margin-bottom: 2em;

    @media only screen and (min-width: @tabletBreakpoint) {
        height: 100dvh;
        max-height: 100dvh;
        margin-bottom: 0;
        overflow: hidden;
    }

    .unauthorised__background {
        height: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.unauthorised__login-wrapper {
    display: grid !important;
    place-items: center;
    margin: 0 !important;

    @media only screen and (min-width: @tabletBreakpoint) {
        height: 100dvh;
        max-height: 100dvh;
        overflow: auto;
    }
}

.unauthorised__slogan {
    margin-top: 0.6em;
    font-family: 'NoeDisplay';
    font-weight: 500;
    font-size: @h2;

    &::after {
        content: '.';
        color: @black;
    }

    + .ui.header {
        font-family: @fontName;
        font-size: @h3;
        color: @black;
    }
}

.unauthorised__login {
    width: 100%;
    max-width: 380px;
    align-self: center;
}

.unauthorised .servicelink {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    li {
        display: inline-block;
        padding-right: 0.5em;
        padding-left: 0.5em;
        line-height: 1em;

        ~ li {
            border-left: 2px solid @white;
        }
    }
}

.wrapper__image {
    margin-bottom: 1em;
    overflow: hidden;
}

.wrapper__features {
    margin-top: 2em;

    h2 {
        margin-top: 2em;
    }
}

label,
.ui.label,
.button__close,
.ui.icon.input > i.icon,
.ui.button,
.ui.menu .item,
.ui.form select,
.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url] {
    transition: none !important;
}

.two-select {
    .ui.segment .two.fields {
        margin-right: 2em;
        margin-bottom: 0;
    }
}

a[href=""] {
    color: @textColor;
    text-decoration: none !important;
    pointer-events: none;
    cursor: default;
}

.mapboxgl-map {
    font-family: @fontName;
}

.mapboxgl-popup-content {
    padding: 0.8em 1em;
    color: @fullBlack;
    font-size: 15px;

    a {
        cursor: pointer;
    }
}

.mapboxgl-notification {
    background: @fullBlack;
    color: @white;
    font-weight: bold;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group.mapboxgl-ctrl > .mapboxgl-ctrl-icon {
    padding: 0.4em 0.6em;
    line-height: 20px;

    > input[type=checkbox] {
        vertical-align: middle;
        margin: 0 0.3em 0.1em 0;
    }

    .icon {
        transform: translateY(0.2em);
    }
}

@waypoint-border-size: 2px;
@waypoint-size: 32px;
@waypoint-bike-color: #167914;
@waypoint-walk-color: #9F123A;
@waypoint-sail-color: #017ABF;
@waypoint-rider-color: #853643;
@waypoint-mtb-color: #846A51;

.waypoint {
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 50%;
    font-weight: 700;
    border: @waypoint-border-size solid;
    z-index: 2;
    width: @waypoint-size;
    height: @waypoint-size;
}

.waypoint__waypoint_bike,
.waypoint_bike {
    color: @waypoint-bike-color;
    border-color: @waypoint-bike-color;

    &::before {
        background-color: @waypoint-bike-color;
    }
}

.waypoint__waypoint_walk,
.waypoint_walk {
    color: @waypoint-walk-color;
    border-color: @waypoint-walk-color;

    &::before {
        background-color: @waypoint-walk-color;
    }
}

.waypoint__waypoint_sail,
.waypoint_sail {
    color: @waypoint-sail-color;
    border-color: @waypoint-sail-color;

    &::before {
        background-color: @waypoint-sail-color;
    }
}

.waypoint__waypoint_rider,
.waypoint_rider {
    color: @waypoint-rider-color;
    border-color: @waypoint-rider-color;

    &::before {
        background-color: @waypoint-rider-color;
    }
}

.waypoint__waypoint_mtb,
.waypoint_mtb {
    color: @waypoint-mtb-color;
    border-color: @waypoint-mtb-color;

    &::before {
        background-color: @waypoint-mtb-color;
    }
}

.waypoint-wrapper {
    background-image: none !important;
    padding-top: 26px;

    strong {
        display: inline-flex;
        width: @waypoint-size;
        height: @waypoint-size;
        margin-left: -round(@waypoint-size * 0.5);
        margin-top: -round(@waypoint-size * 0.5);
    }

    &::before {
        display: none;
    }
}

::placeholder {
    opacity: 1;
}
