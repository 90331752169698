/*******************************
         Site Overrides
*******************************/
.ui.tiny.image {
  background-color: @white;
  width: 80px;
  height: 80px;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .icon {
    margin: 0;
    font-size: 2.5em;
    color: @grey;
  }
}
