/*******************************
        User Overrides
*******************************/
.ui.fluid.dropdown {
  color: @black;
}

.ui.selection.dropdown .menu > .item {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: @fontSize * 0.75;
}

.ui.dropdown.multiple {
  display: flex;
  flex-wrap: wrap;
}

.ui.selection.dropdown > .delete.icon {
  top: 1.3em;
}

.ui.selection.dropdown > .search.icon,
.ui.selection.dropdown > .dropdown.icon {
  top: 1.4em;
}

.ui.selection.dropdown.active,
.ui.selection.dropdown.visible {
  z-index: 1001 !important;
}

.tp-dropdown-allow-parent.ui.dropdown .menu .header {
  cursor: pointer;
}

.ui.dropdown.search.selection .menu {
  container-name: dropdown;
  container-type: inline-size;
}

@container dropdown (min-width: 400px) {
  .ui.dropdown .menu > .header {
    width: 100%;
    padding-bottom: 0.6em;
    padding-top: 0.6em;
    background: @labelBg;
    color: @labelColorDefault;
  }

  .ui.dropdown .menu > .header ~ .header {
    margin-top: 1.5rem;
  }

  .ui.dropdown .menu > .header ~ .item {
    display: inline-block;
    border: 0;
    width: 50%;
  }
}

@container dropdown (min-width: 450px) {
  .ui.dropdown .menu > .header ~ .item {
    width: 33.33%;
  }
}
