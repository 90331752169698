/*******************************
         Site Overrides
*******************************/

/* Bottom */

.ui.segment[class*="bottom attached"] {
    border-radius: 0;

    &:last-child {
        &.active {
            margin-bottom: 1rem;
        }
    }
}

.ui.segment {
    &:last-child {
        margin-bottom: 1rem;
    }

    // segment inside segment
    .ui.tabular.menu {
        border-bottom: solid 1px @white;

        .item {
            border-color: @borderColor;
            border-bottom: 1px solid @borderColor;
            color: @grey;

            &.active {
                background: @white;
                border-bottom: 1px solid @white;
                color: @tabColor;
            }
        }
    }

    .ui.segment[class*="bottom attached"] {
        background: @white;
        border: solid 1px @borderColor;
    }

    // close button
    .icon__close {
        position: absolute;
        right: 1em;
        top: 1em;
        color: @textColor;

        &:hover {
            color: @red;
        }
    }

    &.inverted {
        overflow: hidden;
        border: 1px solid @borderColor;
        border-radius: @defaultBorderRadius;
    }
}

// citynavigator-block-highlights
.citynavigator-block-highlights {
    .segment {
        background: @lightGreen;

        .item-text {
            display: flex;
            align-items: center;

            i.icon {
                font-size: 3rem;
                height: auto;
                width: auto;
                line-height: 1;
                margin-right: 20px;
            }

            span {
                display: block;
                font-weight: @bold;

                &.large {
                    font-size: 24px;
                    font-weight: @bold;
                    margin-bottom: 10px;
                }
            }
        }
    }
}

// sortable
.form-collection-item-sortable {
    margin: -1rem -1rem 2rem -1rem;
    padding: 1.1rem;
    background: #DBE2E2;
}

.form-collection-title {
    display: inline-block;
    padding-left: 0.5rem;
    margin: 0;
    transform: translateY(1px);
}

[data-form-collection-index]:first-child .form-collection-sort--up,
[data-form-collection-index]:last-child .form-collection-sort--down {
    opacity: 0.6;
    cursor: not-allowed;
}

summary {
    &::-webkit-details-marker {
        display: none;
    }

    &::marker {
        content: '';
        display: none;
    }

    .square.outline.icon {
        cursor: pointer;
    }

    .icon.move,
    .plus.icon {
        display: none;
    }

    .ui.icon.buttons {
        margin-top: -0.7rem;

        .button {
            padding: 0.5em 0.3em !important;
            background: #DBE2E2;
        }
    }
}

details.ui.segment:not([open]) {
    padding-bottom: 0;

    summary {
        margin-bottom: 0;

        .plus.icon {
            display: inline-block;
        }

        .minus.icon {
            display: none;
        }
    }
}

// custom
.ui.dimmer .ui.modal div.ui.loader {
    color: rgba(0, 0, 0, .87);
    background-color: @white;
    border: 2em solid @white;
    border-radius: 5px;

    &:before {
        border-color: rgba(0, 0, 0, 0.15);
    }

    &:after {
        border-color: #767676 transparent transparent;
    }
}

.focal-point {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.focal-point__click-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    z-index: 2;
}

.focal-icon__point {
    width: 1px;
    height: 1px;
    display: inline-block;
    background-color: white;
    position: absolute;
}

.focal-icon__cross {
    color: white;
    font-size: 26px;
    display: inline-block;
    position: absolute;
    left: -9px;
    top: -10px;
    font-weight: bold;
    text-shadow: 0 0 3px #888888;
}

.focal-icon__circle {
    width: 51px;
    height: 51px;
    position: absolute;
    left: -27px;
    top: -26px;
    border: 2px solid white;
    display: inline-block;
    border-radius: 50%;
    box-shadow: 0 0 5px #888888;
}

// social media
.social__media.collection-widget {
    > div {
        > .ui.segment {
            padding-left: 0;
            padding-right: 0;
            margin-top: 0;
            margin-bottom: -0.5em;

            &:last-child {
                margin-bottom: 1em;
            }
        }
    }

    .icon.close {
        margin-top: 38px;
    }
}

[draggable] {
    user-select: none;
}

.ui.grid.times .ui.segment {
    padding-left: 0;
    padding-right: 4em;
    padding-bottom: 0;
    max-width: 500px;

    @media only screen and (min-width: @computerBreakpoint) {
        padding-right: 2em;
    }

    .fields {
        width: 100%;
    }

    .icon__close {
        top: ~"calc(50% - 1.5em)";
    }
}

#tp_event_calendar_rules,
#tp_location_calendar_rules {
    .ui.grid.times .ui.segment .icon__close {
        top: 3.65em;
    }
}

.ui.segment.tp-address-form {
    padding: 0;
}
