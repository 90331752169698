/*******************************
         Search Overrides
*******************************/

.ui.grid > [class*="middle aligned"].column:not(.row).has-searchfield {
    display: flex;
    flex-direction: row;
    align-items: center;

    .filter-label {
        display: inline-block;
    }

    .ui.input.searchbar {
        width: auto;
        flex-grow: 1;
    }

    .button {
        display: none;
        position: absolute;
        height: 2rem;
        right: 0;
        top: 0.15rem;
        padding: 0.5rem;
        background: none;
        border: none;
        color: @deleteIconColor;
        cursor: pointer;
        z-index: 1;

        .icon {
            &,
            &::before {
                pointer-events: none;
            }
        }
    }

    .button.clear {
        right: 2.5rem;
        top: 0.3rem;
    }

    input:not(:placeholder-shown) {
        padding-left: 1rem !important;

        + i {
            display: none;
        }

        ~ .button {
            display: block;
        }
    }
}

@media only screen and (min-width: 0) and (max-width: (@computerBreakpoint + 60)) {
.ui.column.grid>[class*="eight wide"].column,
.ui.grid>.column.row>[class*="eight wide"].column,
.ui.grid>.row>[class*="eight wide"].column,
.ui.grid>[class*="eight wide"].column{
        &.has-searchfield{
            width: 100% !important;
        }
        &.has-searchfield + div{
            width: 100% !important;
            .ui.pagination.menu{
                text-align: right;
            }
        }
    }
}
.ui.input.searchbar{
    position: relative;
    width: 100%;
    height: 38px;
    margin: 20px 0 0;
    display: block;

    @media only screen and (min-width: @tabletBreakpoint) {

        display: inline-block;
        margin:  0;
        margin-left: 24px;

        &::before{
            content: '';
            position:absolute;
            top: 10%;
            left: -10px;
            width: 1px;
            height: 80%;
            background-color: @deleteIconColor;
        }
    }

    input{
        border: 0;
        border-bottom: 1px solid @inputBorderColor;
        width: 100%;
        cursor: pointer;

        @media only screen and (min-width: @tabletBreakpoint) {
            border: 1px solid @white;
            width: 0;
        }
        &::-ms-clear {
            display: none;
        }
        &:focus{
            transition: width 0.5s;
            width: 100%;
        }
        //When input has focus or placeholder is not shown (i.e. input has value)
        &:focus,
        &.has-value {
            border-bottom: 1px solid @inputBorderColor;
            width: 100%;
        }

        &.has-value ~ .clear-input {
            display: block !important;
        }
    }

    .search.icon{
        opacity: 1;
        &::before{
            font-size: 18px;
            color: @deleteIconColor;
        }
    }

    .clear-input{
        display:none;
        position: absolute;
        right: -8px;
        top: 9px;
        color: @deleteIconColor;
        opacity: 0.5;

        &:hover,
        &:focus{
            transition: all 0.5s;
            transition-delay: 0.5s;
            cursor: pointer;
            color: @inputBorderColor;
            opacity: 0.5;
        }
    }
}
