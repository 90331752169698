/*******************************
         Site Overrides
*******************************/
.ui.header {
    color: @primaryColor;
    font-family: @primaryHeaderFont;
    font-weight: @primaryHeaderFontWeight;

    .ui.label.ui.label {
        font-family: @fontName;
        text-transform: uppercase;
        color: @black;
        background: lighten(@primaryColor, 50%);
        border-color: lighten(@primaryColor, 50%);
    }
}

h1.ui.header {
    @media only screen and (max-width: @tabletBreakpoint) {
        font-size: 1.5em;
        line-height: 2em;
    }
}

.ui.tab h2.ui.header {
    color: @secondaryColor;
    font-size: @h2;
}
