/*******************************
    User Variable Overrides
*******************************/
.item {
    .waypoint,
    .awesome-marker {
        margin-right: 1rem;
    }

    a {
        text-decoration: underline;
    }
}

.startpoint .item .icon-odp-start {
    margin-left: 2px;
}

.field .ui.items {
    margin-top: 0;
}