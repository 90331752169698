/*******************************
         Site Overrides
*******************************/
.field__update {
    display: flex;
    align-items: center;

    a {
        flex: 0 0 auto;
        margin-left: 10px;
        text-decoration: underline;

        i {
            text-decoration: none;
        }
    }
}

input.disabled {
    opacity: 0.5;
}

.field.field-action {
    position: relative;
    padding: 10px;
    background: @white;
    color: @linkColor;
    line-height: 1.2em;
    border: 1px solid @inputBorderColor;
    border-radius: 4px;

    a {
        text-decoration: underline;
        font-size: 13px;
    }

    .icon.delete {
        cursor: pointer;
        position: absolute;
        right: 7px;
        top: 0.8em;
        color: @deleteIconColor;
        opacity: .8;
    }
}

.ui.icon.input > i.icon:has(>svg.tp-inline-icon) {
    display: grid;
    place-items: center;
}