/*******************************
    User Variable Overrides
*******************************/

.ui.card {
  .ui.image {
    padding: 1em 0 2em 0;
    max-width: 100px;
    width: 100%;
  }

  .ui.header {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;

    .sub.header {
      font-family: @headerFont;
      font-weight: @bold;
      font-size: 14px;
      margin-top: 10px;
      color: @black;
    }
  }

  .meta {
    font-weight: @bold;
    font-size: 12px !important;
  }
}

.ui.cards > .card {
  margin-top: 1em;
}

.ui.cards > .card > .content > .header,
.ui.card > .content > .header {
  font-family: @primaryHeaderFont;
  font-weight: @primaryHeaderFontWeight;
}
