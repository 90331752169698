/*******************************
         Site Overrides
*******************************/
.ui.labels .label,
.ui.label {
  font-size: @fontSize * 0.7;
  @media only screen and (min-width: @largeMonitorBreakpoint) {
    font-size: @fontSize;
  }
}

.ui.basic.label {
  font-weight: @bold;
}

.wrapper__features {
  .ui.label {
    margin: 0 5px 5px 0;
  }
}

.ui.circular.label:not(.popup-initiator) {
  min-height: auto;
  min-width: auto;
  width: 18px;
  height: 18px;
  font-size: 9px;
  line-height: 16px;
  padding: 0 !important;
  margin-top: -1px !important;
}

// orange label
.ui.orange.label {
  background: rgb(255, 181, 53) !important;
  border: none !important;
  box-shadow: none;

  &:hover {
    background: rgb(79, 156, 176) !important;
  }
}

.ui.card .ui.attached.label {
  border: none;
}
