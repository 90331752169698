/*******************************
         Site Overrides
*******************************/

#content {
  >.ui.grid:first-child {
    margin-bottom: 0rem;
    +.grid {
      margin-top: -1rem;
    }
  }
}

.content-container{
    margin-top: 1em;
    width: 100%;
    padding-top: 10px;
}

.message ~ .ui.grid.menu__top-sticky {
    margin-top: 1rem;
}