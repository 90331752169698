/*******************************
         Site Overrides
*******************************/

i.flag.zh::before {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/svg/1f1e8-1f1f3.svg");
}

i.flag.en::before {
  background-image: url("https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/svg/1f1fa-1f1f8.svg");
}

i.flag.fy::before {
  background: url('../assets/images/flags/fy.svg') no-repeat top left;
  background-size: contain;
}

i.flag.inactive {
  opacity: 0.2;
}

i.flag:not(.icon)::before {
  height: 1.1em;
  width: 1.3em;
  margin-right: 0.25em;
  background-size: contain;
  background-position: center left;
}
