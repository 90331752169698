/*******************************
    User Variable Overrides
*******************************/

.ui.list > .item {
  padding: 0.5em 0;

  .header {
    margin-bottom: 0.25em;
  }
}