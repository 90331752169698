/*******************************
         Site Overrides
*******************************/

.ui.table {
  .icon {
    color: @grey;
  }

  thead {
    tr {
      th {
        color: @grey;
        white-space: nowrap;

        a {
          color: @grey;

          .icon {
            display: inline;
            color: currentColor;
            margin-left: 0.25rem;
          }
        }

        i {
          color: @black;
        }
      }
    }
  }

  &.stackable {
    td,
    th {
      &.right.aligned {
        min-width: 150px;

        span {
          display: none;
        }
      }
    }

    th:first-child:not(.collapsing) {
      width: 35%;

      ~ th:last-child {
        width: 6%;
      }

      &:nth-last-child(3) ~ th:nth-last-child(1n+2) {
        width: ((100% - 35 - 6) / 1);
      }

      &:nth-last-child(4) ~ th:nth-last-child(1n+2) {
        width: ((100% - 35 - 6) / 2);
      }

      &:nth-last-child(5) ~ th:nth-last-child(1n+2) {
        width: ((100% - 35 - 6) / 3);
      }

      &:nth-last-child(6) ~ th:nth-last-child(1n+2) {
        width: ((100% - 35 - 6) / 4);
      }
    }
  }

  tbody {
    tr {
      transition: @transition;

      &:nth-child(even) {
        background: @tableBg;
      }

      &:hover {
        background: @tableBgHover;
      }

      &:last-child {
        td {
          border-bottom: @rowBorder;
        }
      }
    }

    td {

      a {
        text-decoration: underline;
      }

      &:last-child:not(.disabled) {
        color: @grey;

        form,
        button,
        a.icon {
          color: @grey;
          transition: @transition;
          text-decoration: none;

          &:hover {
            color: @linkColor;
          }
        }

        .icon {
          color: currentColor;
        }

        button {
          margin: 0;
          padding: 0;
          background: none;
          border: none;
          cursor: pointer;

          > .icon {
            color: currentColor;
          }
        }

        > * {
          display: inline-block;
        }
      }
    }
  }

  .ui.label {
    opacity: 0.7;
  }

  tfoot {
    th {
      background: #f8f8f9;
      border-top: none;
      border-bottom: solid 1px @borderColor;
      font-weight: bold;
    }
  }
}

/* Standard */
.ui.table {
  font-size: @small;

  @media only screen and (min-width: @largeMonitorBreakpoint) {
    font-size: @medium;
  }
}

.ui.table td.actionicons {
  @media only screen and (min-width: @tabletBreakpoint) {
    text-align: right;
  }
}

.ui.very.compact.table.price {
  background-color: transparent;
  margin: 0;

  tr {
    background-color: transparent;

    &:nth-child(1n+2) td {
      border-top: 1px solid @offWhite;
    }
  }

  td {
    border: none;
    padding-left: 0;
    word-break: break-all;
  }
}

.ui.table tbody td:last-child:not(.disabled) {
  color: @textColor;
}
