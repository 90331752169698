/*******************************
        User Overrides
*******************************/
.ui.tab {
  .ui.header {
    color: @primaryColor;
    font-size: 18px;
    padding-bottom: 10px;
  }
  a.button {
    margin-top: 1em;
  }
  .ui-sortable ~ a.button {
    margin-bottom: 1em;
  }
  .focal-point ~ a.button {
    margin-bottom: 1em;
    display: table;
  }
}

.ui.grid + .tabs {
  margin-top: 1rem;
}

.active.tab {
  .ui.attached.segment {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}

.tabs {
  .ui.attached.segment.active {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  }
}
